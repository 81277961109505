var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex flex-column flex-grow-1 ml-2 mx-2"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 mb-3"},[_c('PageTitle',{staticClass:"font font-weight-bold",attrs:{"text":"Stock Management"}}),_c('div',[_c('v-text-field',{staticClass:"mt-2 font font-size-sm black--text",staticStyle:{"min-width":"500px"},attrs:{"dense":"","append-icon":"search","light":"","single-line":"","solo-inverted":"","placeholder":"Search by product name here  ...","hide-details":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 mx-2",attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.refreshPage($event)}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:" font font-weight-medium font-size-sm"},[_vm._v("Refresh Page")])])],1),_c('v-alert',{staticClass:"font-weight-bold text-uppercase font mt-1",attrs:{"dense":"","type":"success"}},[_c('span',[_vm._v(_vm._s(_vm._f("timestamp")(_vm.date,"MMMM, YYYY")))])])],1),_c('div',{staticClass:"buttonText ml-1 mt-3",attrs:{"id":"result"}},[_c('h4',{staticClass:"blue-grey--text font",staticStyle:{"margin-left":"10px"}},[_vm._v(" List of Available Stocks For Products ")])]),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:"font box-shadow-light ",attrs:{"headers":_vm.productHeaders,"items":_vm.filteredProductStocks,"item-key":"product","server-items-length":_vm.productsPaginate.total,"loading-text":"Loading... Please wait","loading":_vm.isLoading,"sort-by":['product'],"options":_vm.productOptions,"footer-props":{
              itemsPerPageOptions: [10, 20, 30]
            }},on:{"update:options":function($event){_vm.productOptions=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [(item.productId)?_c('v-avatar',{staticStyle:{"margin-right":"-80px"},attrs:{"color":"primary","size":"35"}},[_c('span',{staticClass:"white--text subtitle-1 font text-uppercase"},[_vm._v(_vm._s(item.productId.name.charAt(0)))])]):_vm._e()]}},{key:"item.productId",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v(_vm._s(item.productId.name)+" ")])])]}},{key:"item.quantity",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":""}},[_c('span',{staticClass:" font font-weight-bold"},[_vm._v(_vm._s(item.quantity)+" ")])])]}},{key:"item.minimumThreshold",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":item.quantity >= item.productId.minimumThreshold
                    ? 'success'
                    : 'warning'}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(item.productId.minimumThreshold)+" ")])])]}}])})],1)]],2)],1),_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"ml-2 mt-3 white--text font font-weight-medium",staticStyle:{"font-size":"12px"},attrs:{"color":"primary","to":{ name: 'master.lubes.order' }}},[_vm._v(" New Lube Order"),_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-plus")])],1),_c('h4',{staticClass:"blue-grey--text font",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" List of Available Stocks For Lubes ")])],1),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:"font box-shadow-light ",attrs:{"headers":_vm.headers,"items":_vm.filteredLubeStocks,"item-key":"product","server-items-length":_vm.paginate.total,"loading-text":"Loading... Please wait","loading":_vm.isLoading,"sort-by":['product'],"options":_vm.options,"footer-props":{
              itemsPerPageOptions: [10, 20, 30]
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [(item.productId)?_c('v-avatar',{staticStyle:{"margin-right":"-80px"},attrs:{"color":"primary","size":"35"}},[_c('span',{staticClass:"white--text font subtitle-1 text-uppercase"},[_vm._v(_vm._s(item.productId.name.trim().charAt(0)))])]):_vm._e()]}},{key:"item.product",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"font font-weight-medium",attrs:{"label":"","small":"","color":"primary"}},[(item.productId)?_c('span',[_vm._v(_vm._s(item.productId.name))]):_vm._e()])]}},{key:"item.currentStockQuantity",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"font font-weight-bold",attrs:{"label":"","small":""}},[_c('span',[_vm._v(_vm._s(item.currentStockQuantity))])])]}},{key:"item.minimumThreshold",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":Number(item.currentStockQuantity) >= item.minimumThreshold
                    ? 'success'
                    : 'warning',"label":"","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.minimumThreshold))])])]}}])})],1)]],2)],1),_c('v-snackbar',{attrs:{"timeout":4000,"top":"","color":_vm.status},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.message))]),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.showSnackBar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }