<template>
  <v-container>
    <div class="d-flex flex-column flex-grow-1 ml-2 mx-2">
      <div class="d-flex flex-row flex-grow-1 mb-3">
        <PageTitle text="Stock Management" class="font font-weight-bold" />
        <div>
          <v-text-field
            dense
            append-icon="search"
            light
            single-line
            v-model="search"
            style="min-width: 500px;"
            solo-inverted
            placeholder="Search by product name here  ..."
            class="mt-2 font font-size-sm black--text"
            hide-details
            flat
          />
        </div>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              @click.stop="refreshPage"
              v-on="on"
              color="primary"
              icon
              class="mt-2 mx-2"
            >
              <i class="material-icons-outlined">refresh</i>
            </v-btn>
          </template>
          <span class=" font font-weight-medium font-size-sm"
            >Refresh Page</span
          >
        </v-tooltip>
      </div>

      <v-alert
        dense
        class="font-weight-bold text-uppercase font mt-1"
        type="success"
        ><span>{{ date | timestamp("MMMM, YYYY") }}</span></v-alert
      >
    </div>

    <div class="buttonText ml-1 mt-3" id="result">
      <h4 style=" margin-left: 10px" class="blue-grey--text font">
        List of Available Stocks For Products
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="productHeaders"
              :items="filteredProductStocks"
              item-key="product"
              :server-items-length="productsPaginate.total"
              loading-text="Loading... Please wait"
              :loading="isLoading"
              :sort-by="['product']"
              :options.sync="productOptions"
              :footer-props="{
                itemsPerPageOptions: [10, 20, 30]
              }"
              class="font box-shadow-light "
            >
              <template #item.avatar="{item}">
                <v-avatar
                  v-if="item.productId"
                  color="primary"
                  style="margin-right: -80px"
                  size="35"
                >
                  <span class="white--text subtitle-1 font text-uppercase">{{
                    item.productId.name.charAt(0)
                  }}</span>
                </v-avatar>
              </template>
              <template #item.productId="{item}">
                <v-chip label small color="primary">
                  <span class="font font-weight-medium"
                    >{{ item.productId.name }}
                  </span>
                </v-chip>
              </template>
              <template #item.quantity="{item}">
                <v-chip label small>
                  <span class=" font font-weight-bold"
                    >{{ item.quantity }}
                  </span>
                </v-chip>
              </template>
              <template #item.minimumThreshold="{item}">
                <v-chip
                  label
                  small
                  :color="
                    item.quantity >= item.productId.minimumThreshold
                      ? 'success'
                      : 'warning'
                  "
                >
                  <span class="ft font-weight-medium"
                    >{{ item.productId.minimumThreshold }}
                  </span>
                </v-chip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col>
    </v-row>
    <div class="buttonText ml-1" id="result">
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="primary"
        class="ml-2 mt-3 white--text font font-weight-medium"
        style="font-size: 12px"
        :to="{ name: 'master.lubes.order' }"
      >
        New Lube Order<v-icon size="20" color="white">mdi-plus</v-icon>
      </v-btn>

      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="blue-grey--text font"
      >
        List of Available Stocks For Lubes
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="filteredLubeStocks"
              item-key="product"
              :server-items-length="paginate.total"
              loading-text="Loading... Please wait"
              :loading="isLoading"
              :sort-by="['product']"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: [10, 20, 30]
              }"
              class="font box-shadow-light "
            >
              <template #item.avatar="{item}">
                <v-avatar
                  v-if="item.productId"
                  color="primary"
                  style="margin-right: -80px"
                  size="35"
                >
                  <span class="white--text font subtitle-1 text-uppercase">{{
                    item.productId.name.trim().charAt(0)
                  }}</span>
                </v-avatar>
              </template>
              <template #item.product="{item}">
                <v-chip
                  label
                  small
                  color="primary"
                  class="font font-weight-medium"
                >
                  <span v-if="item.productId">{{ item.productId.name }}</span>
                </v-chip>
              </template>
              <template #item.currentStockQuantity="{item}">
                <v-chip label small class="font font-weight-bold">
                  <span>{{ item.currentStockQuantity }}</span>
                </v-chip>
              </template>
              <template #item.minimumThreshold="{item}">
                <v-chip
                  :color="
                    Number(item.currentStockQuantity) >= item.minimumThreshold
                      ? 'success'
                      : 'warning'
                  "
                  label
                  small
                >
                  <span class="font-weight-bold">{{
                    item.minimumThreshold
                  }}</span>
                </v-chip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >

    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import { Api } from "@/api/Api";
import { timestamp } from "../../filters/timestamp";
import { uniqBy } from "lodash";
import moment from "moment";
export default {
  name: "Index",
  components: {
    PageTitle
  },
  filters: {
    timestamp
  },
  data() {
    return {
      search: "",
      showSnackBar: false,
      status: "success",
      message: "",
      isLoading: false,
      stocks: [],
      productStocks: [],
      date: "",
      options: {},
      productOptions: {},
      productHeaders: [
        {
          text: "",
          value: "avatar"
        },
        { text: "Product", value: "productId" },
        { text: "Quantity", value: "quantity" },
        { text: "Minimum Threshold", value: "minimumThreshold" }
      ],
      headers: [
        {
          text: "",
          value: "avatar"
        },
        {
          text: "Product",
          value: "product"
        },
        {
          text: "Quantity",
          value: "currentStockQuantity"
        },
        {
          text: "Minimum Threshold",
          value: "minimumThreshold",
          align: "center"
        }
      ],
      paginate: {
        page: 1,
        limit: 2,
        total: 0
      },
      productsPaginate: {
        page: 1,
        limit: 12,
        total: 0
      }
    };
  },
  computed: {
    filteredProductStocks() {
      return this.productStocks.filter(({ productId }) => {
        return productId?.name?.toLowerCase().indexOf(this.search) !== -1;
      });
    },
    filteredLubeStocks() {
      return this.stocks.filter(({ productId }) => {
        return productId?.name?.toLowerCase().indexOf(this.search) !== -1;
      });
    }
  },
  watch: {
    options(paginate) {
      this.getStockItems({ page: paginate.page, limit: paginate.itemsPerPage });
    },
    productOptions(paginate) {
      this.stockForProducts({
        page: paginate.page,
        limit: paginate.itemsPerPage
      });
    }
  },
  methods: {
    async getStockItems({ isPaginated = true, page, limit }) {
      try {
        this.isLoading = true;
        const response = await Api().get(
          `/stocks/available/monthly?isPaginated=${isPaginated}&page=${page}&limit=${limit}`
        );
        if (response) {
          this.stocks = uniqBy(
            response.data.paginateObj.docs,
            "productId.name"
          );
          const { page, total, limit } = response.data.paginateObj;
          this.paginate = { page, total: total, limit };
          this.date =
            response.data.paginateObj.docs.length > 0
              ? response.data.paginateObj.docs[0].date
              : moment();
          this.isLoading = false;
        }
      } catch (e) {
        this.isLoading = false;
        this.showSnackBar = true;
        this.message = e.response.data.message
          ? e.response.data.message
          : e.message;
        this.status = "red";
      }
    },

    async stockForProducts({ isPaginated = true, page, limit }) {
      try {
        this.isLoading = true;
        const response = await Api().get(
          `/stocks/for/products?isPaginated=${isPaginated}&page=${page}&limit=${limit}`
        );
        if (response) {
          const { page, total, limit, docs } = response.data.paginateObj;
          this.productStocks = uniqBy(docs, "productId.name");
          this.productsPaginate = { page, total, limit };
          this.isLoading = false;
        }
      } catch (e) {
        this.isLoading = false;
        this.showSnackBar = true;
        this.message = e.response.data.message
          ? e.response.data.message
          : e.message;
        this.status = "red";
      }
    },
    async refreshPage() {
      this.getStockItems({
        page: this.paginate.page,
        limit: this.paginate.limit
      });
      this.stockForProducts({
        page: this.productsPaginate.page,
        limit: this.productsPaginate.limit
      });
    }
  },
  async created() {
    this.date = moment();
  }
};
</script>

<style scoped></style>
